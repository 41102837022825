import i18n from "@/i18n";

export const STATUS_NAMES = {
    "offline": "Không hoạt động",
    "online": "Đang hoạt động",
    "away": "Đi vắng",
    "busy": "Đang bận"
}
export const INTERNAL_REQUEST_DEFAULT = {
    type: null,
    basket_code: null,
    cell_id: null,
    money: null,
    note: null,
    order_tracking_id: null,
    quantity: null,
    receipt_tracking_id: null,
    sku: null,
    working_shift: null,
    working_date: null,
}

export const INTERNAL_PICKUP_REDUCE = 3
export const INTERNAL_RETURN_HAS_MONEY = 4
export const INTERNAL_REQUEST_BONUS = 16
export const INTERNAL_REQUEST_PUNISHMENT = 17
export const ALLOW_REQUEST_CREATE_TYPES = [
    10, 11, 12, 19,
    51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
    91, 99
]

export const RETURN_RECEIVE_REASONS = [
    {
        value: 'Mất / Thiếu hàng',
        text: 'Mất / Thiếu hàng'
    },
    {
        value: 'P.Bì bị rạch mở, lộ mã kích hoạt',
        text: 'P.Bì bị rạch mở, lộ mã kích hoạt'
    },
    {
        value: 'Hàng bị ướt',
        text: 'Hàng bị ướt'
    },
    {
        value: 'Hàng bị vỡ / hỏng',
        text: 'Hàng bị vỡ / hỏng'
    },
    {
        value: 'Hàng bị tráo / đổi',
        text: 'Hàng bị tráo / đổi'
    },
    {
        value: 'VC. Trả nhầm đơn',
        text: 'VC. Trả nhầm đơn'
    },
    {
        value: 'Thừa hàng',
        text: 'Thừa hàng'
    },
    {
        value: 'Khác',
        text: 'Khác'
    },
]

export const RETURN_IMPORT_REASONS = [
    {
        value: 'HỦY HÀNG - Đối tác yêu cầu',
        text: 'HỦY HÀNG - Đối tác yêu cầu'
    },
    {
        value: 'TRẢ HÀNG - Đối tác yêu cầu',
        text: 'TRẢ HÀNG - Đối tác yêu cầu'
    },
    {
        value: 'TRẢ HÀNG - Hình thức không đạt',
        text: 'TRẢ HÀNG - Hình thức không đạt'
    },
    {
        value: 'TRẢ HÀNG - Bao bì hư hại',
        text: 'TRẢ HÀNG - Bao bì hư hại'
    },
    {
        value: 'TRẢ HÀNG - Hàng hóa hư hại',
        text: 'TRẢ HÀNG - Hàng hóa hư hại'
    },
    {
        value: 'Không có hàng',
        text: 'Không có hàng'
    },
    {
        value: 'Giao một phần',
        text: 'Giao một phần'
    },
    {
        value: 'Hết hạn sử dụng',
        text: 'Hết hạn sử dụng'
    },
    {
        value: 'Khác',
        text: 'Khác'
    },
]

export const GOODS_ISSUE_STATE = [
    {
        text: i18n.t('labels.order_status_-100'),
        value: -100
    },
    {
        text: i18n.t('labels.order_status_-50'),
        value: -50
    },
    {
        text: i18n.t('labels.order_status_0'),
        value: 0
    },
    {
        text: i18n.t('labels.order_status_20'),
        value: 20
    },
    {
        text: i18n.t('labels.order_status_35_1'),
        value: 35
    },
    {
        text: i18n.t('labels.order_status_40'),
        value: 40
    },
    {
        text: i18n.t('labels.order_status_60_1'),
        value: 60
    },
    {
        text: i18n.t('labels.order_status_100_1'),
        value: 100
    },
    {
        text: i18n.t('labels.order_status_160_1'),
        value: 160
    },
    {
        text: i18n.t('labels.order_status_200_2'),
        value: 200
    },
    {
        text: i18n.t('labels.order_status_220_1'),
        value: 220
    },
    {
        text: i18n.t('labels.order_status_240_1'),
        value: 240
    },
]

export const PICKUP_COMMAND_STATUS = [
    {
        text: 'Chưa Pick',
        value: 0
    },
    {
        text: 'Đã Pick',
        value: 1
    },
]

export const PICKUP_COMMAND_PROCESS_TYPE = [
    {
        text: '1',
        value: 1
    },
    {
        text: '2',
        value: 2
    },
    {
        text: '3',
        value: 3
    },
]

export const PICKUP_COMMAND_TYPE = [
    {
        text: '01 sp',
        value: 0
    },
    {
        text: '≤32 cm || ≥ 02 sp || 01 sku',
        value: 1
    },
    {
        text: '≤32 cm || ≥ 02 sp || 02 sku',
        value: 2
    },
    {
        text: '≤32 cm || ≤ 20 sp || 03 sku (↑)',
        value: 3
    },
    {
        text: '>32 cm || ≥ 02 sp || 01 sku',
        value: 4
    },
    {
        text: '>32 cm || ≥ 02 sp || 02 sku',
        value: 5
    },
    {
        text: '>32 cm || ≤ 20 sp || 03 sku (↑)',
        value: 6
    },
    {
        text: '> 20 sp',
        value: 100
    },
    {
        text: 'Tracking ID',
        value: 101
    },
]

export const DELIVERY_STATE = [
    {
        text: i18n.t('labels.delivery_status_50'),
        value: 50
    },
    {
        text: i18n.t('labels.delivery_status_55'),
        value: 55
    },
    {
        text: i18n.t('labels.delivery_status_100'),
        value: 100
    },
    {
        text: i18n.t('labels.delivery_status_200'),
        value: 200
    },
    {
        text: i18n.t('labels.delivery_status_225'),
        value: 225
    },
    {
        text: i18n.t('labels.delivery_status_250'),
        value: 250
    },
    {
        text: i18n.t('labels.delivery_status_275'),
        value: 275
    },
    {
        text: i18n.t('labels.delivery_status_400'),
        value: 400
    },
    {
        text: i18n.t('labels.delivery_status_401'),
        value: 401
    },
    {
        text: i18n.t('labels.delivery_status_402'),
        value: 402
    },
    {
        text: i18n.t('labels.delivery_status_700'),
        value: 700
    },
    {
        text: i18n.t('labels.delivery_status_800'),
        value: 800
    },
    {
        text: i18n.t('labels.delivery_status_999'),
        value: 999
    },
]

export const STAMP_STATUS = [
    {
        text: i18n.t('labels.not_yet_1'),
        value: 0
    },
    {
        text: i18n.t('labels.had'),
        value: 1
    },
]

export const IMAGE_OPTIONS = [
    {
        text: i18n.t('labels.yes'),
        value: 'y'
    },
    {
        text: i18n.t('labels.no'),
        value: 'n'
    },
]

export const ACTIVE_DEACTIVE_OPTIONS = [
    {
        text: 'Bật',
        value: 1
    },
    {
        text: 'Tắt',
        value: 0
    },
]

export const TRANSPORT_OPTIONS = [
    {
        text: 'Road',
        value: 'road'
    },
    {
        text: 'Fly',
        value: 'fly'
    },
]

export const YES_NO_OPTIONS = [
    {
        text: 'Yes',
        value: 1
    },
    {
        text: 'No',
        value: 0
    },
]

export const YES_NO_LANG_OPTIONS = [
    {
        text: i18n.t('labels.yes'),
        value: 1
    },
    {
        text: i18n.t('labels.no'),
        value: 0
    },
]

export const ACTIVE_OPTIONS = [
    {
        text: 'Hoạt động',
        value: 1
    },
    {
        text: 'Dừng hoạt động',
        value: 0
    },
]

export const SEAL_STATUS_OPTIONS = [
    {
        text: i18n.t('labels.sealed'),
        value: 1
    },
    {
        text: i18n.t('labels.unsealed'),
        value: 0
    },
]

export const DISCOUNT_TYPE_OPTIONS = [
    {
        text: "%",
        value: 1
    },
    {
        text: i18n.t('labels.amount'),
        value: 2
    },
]

export const STATUS_OPTIONS = [
    {
        text: 'Active',
        value: 1,
        color: 'success--text',
    },
    {
        text: 'Inactive',
        value: 0,
        color: 'error--text',
    },
]

export const GOODS_RETURN_SEAL_STATUS = [
    {
        text: 'Còn niêm phong',
        value: 1
    },
    {
        text: 'Mất niêm phong',
        value: 0
    },
]

export const WORKING_TIMES = [
    {
        text: 'Sáng',
        value: 1
    },
    {
        text: 'Chiều',
        value: 2
    },
    {
        text: 'Tối',
        value: 3
    },
    {
        text: 'Ngoài giờ',
        value: 0
    },
]

export const SOURCES = [
    {
        text: 'SSC',
        value: -2
    },
    {
        text: 'TVC',
        value: -1
    },
    {
        text: 'Shopee',
        value: 1
    },
    {
        text: 'Lazada',
        value: 2
    },
    {
        text: 'Tiktok Shop',
        value: 5
    },
]

export const E_MARKET_SHOPEE = 1
export const E_MARKET_LAZADA = 2
export const E_MARKET_TIKTOK = 5

export const BARCODE_MAT_NIEM_PHONG = 'SSC-MNP-SSC'
export const BARCODE_REMOVE = 'SSC-RMRT-SSC'
export const BARCODE_HU_HAI = 'SSC-HUHAI-SSC'
export const BARCODE_REMOVE_HANDOVER = 'SSC-RMHO-SSC'
export const BARCODE_REMOVE_RECEIPT = 'SSC-RMRC-SSC'
export const BARCODE_FINISH_PACKING_1SKU = 'SSC-F1SKU-SSC'
export const BARCODE_PICKUP_LATER = '88SSC98'

export const POS_TABLE_PACKING = 12

export const ACTIVITIES_LOGS = {
    ACTION: {
        RECEIPT: 1, // nhap kho
        RECEIPT_STOWING: 2, // len ke nhap kho
        PICKUP: 3, // pickup
        PACKING: 4, // dong goi
        HANDOVER: 5, // ban giao van chuyen
        RETURN_RECEIVE: 6, // nhan hoan
        RETURN_IMPORT: 7, // nhap hoan
        RETURN_STOWING: 8, // len ke hang hoan
        RECOUP: 9, // boi thuong,
    },
    JOB: {
        ITEM: 1, // item
        ORDER: 2, // order
        FAIL_EMPLOYEE: 3, // fail by employee
        FAIL_SSC: 4, // fail by ssc,
        LOG: 99,
    },
    WORKING_SHIFT: {
        SA: 1, // ca sang
        CH: 2 // ca chieu
    },
}

export const CUSTOMER_PERMISSIONS = [
    'goods_receipt_create', // ky gui hang hoa
    'goods_return_history', // lich su hang hoan
    'order_create_single', // tao don le
    'order_create_excel', // tao don excel
    'order_create_e_market', // tao don san
    'order_create_select_discount', // tao don chon km
    'goods_manager', // qly hang hoa
    'goods_reserved', // giu ton
    'goods_transfer', // chuyen hang noi bo
    'order_manager', // qly don hang
    'order_dashboard_manager', // thong ke
    'order_cancel_and_change', // huy - doi don hang
    'goods_syn_e_market', // dong bo san
    'order_detail_price', // chi tiet don - gia sp
    'order_download_handover_file', // chi tiet don - gia sp
    'create_order_invoice', // tao hoa don voi don le
    'prevent_pickup_multiple', // tao hoa don voi don le
]

export const INTERNAL_REQUEST_PERMISSIONS = [
    'administration_request', // yeu cau xu ly
    'request_process', // xu ly yeu cau
]

export const INVOICE_PERMISSIONS = [
    'administration_invoice', // yeu cau xu ly
]

export const CUSTOMER_MATERIAL_WAREHOUSE_PERMISSIONS = [
    'material_receipt_create',
    'material_manager',
    'material_receipt_history',
    'goods_production_record',
    'goods_production_requirement',
    'goods_production_quantitative',
]

export const CUSTOMER_POS_PERMISSIONS = [
    // 'pos_timekeeping',
    'pos_goods_transfer', // chuyen hang noi bo
    'pos_goods_manager', // qly hang hoa
    // 'pos_goods_uid_manager', // qly uid
    'pos_goods_receipt_create', // nhap pos
    'pos_goods_receipt_history', // nhap pos
    // 'pos_goods_set_position', // len ke
    // 'pos_goods_pickup', // pickup
    // 'pos_goods_set_action', // cbi hang
    // 'pos_order_create', // ban hang
    'pos_order_list', // don hang
    'pos_order_online_list', // don online -list
    'pos_order_create_single', // don online -list
    // 'pos_order_online_pickup', // don online -pickup
    // 'pos_order_online_packing', // don online - dong goi
    // 'pos_order_online_handover', // don online - ban giao
    // 'pos_order_online_return_receive', // don online - nhan hoan
    // 'pos_order_online_return_import', // don online - nhap hoan
]

export const CUSTOMER_ADMINISTRATION_PERMISSIONS = [
    'administration_receipt_history', // lich su nhap kho
    'administration_report_revenue',
    'administration_report_cost_profit',
    'administration_goods_report',
    'administration_goods_price',
    'administration_goods_cost',
    'administration_supplier',
    'administration_supply',
    'administration_goods_category',
    'administration_goods_profile',
    'administration_pos_goods_display_request',
    'administration_pos_promotion_config',
    'administration_pos_accrue_point_config',
    'administration_pos_voucher_config',
    'administration_goods_missing',
    'administration_zns',
    'administration_goods_debt',
    'administration_present',
]

export const NUMBER_TO_TEXT = {
    0: "ZPK",
    1: "YNJ",
    2: "XMH",
    3: "WKG",
    4: "VJF",
    5: "UHE",
    6: "TGD",
    7: "SFC",
    8: "REB",
    9: "QDA"
};

export const NOTIFY_TYPE_OPTIONS = [4, 98]
export const NOTIFY_STATUS_OPTIONS = [0, 1]
export const SUPPLIER_TYPE_OPTIONS = [0, 1, 2]
export const SUPPLIER_CLASSIFY_OPTIONS = [1, 2]
export const PROFILE_TYPE_OPTIONS = [1, 2, 3]

export const ID_DELIVERY_GHN = 3
export const ID_DELIVERY_GHTK = 4
export const ID_DELIVERY_VNP = 33
export const ID_DELIVERY_JNT = 36
export const ID_DELIVERY_SPX = 74
export const ID_DELIVERY_VTP = 1
export const ID_DELIVERY_NJV = 39
export const ALLOW_FIELD_DELIVERY_ACCOUNT_GHTK = ['token']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_GHN = ['token', 'shop_id']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_SPX = ['user_id', 'user_secret']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_JNT = ['company_id', 'customer_id', 'token']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_VNP = ['username', 'password']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_VTP = ['username', 'password', 'shop_id', 'service']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_NJV = ['user_id', 'user_secret']

export const DEFAULT_SSC_DELIVERY_CONTRACTS = [-1, 7]

export const SSC_DELIVERY_ID = -1
export const TVC_DELIVERY_ID = -2
export const OPTIMIZE_DELIVERY_ID = -3
export const BY_WEIGHT_DELIVERY_ID = -4
export const DEFAULT_CONTRACT_IDS = [SSC_DELIVERY_ID, TVC_DELIVERY_ID, BY_WEIGHT_DELIVERY_ID]
export const DELIVERY_TYPE_PROVINCIAL = 1
export const DELIVERY_TYPE_INTER_PROVINCIAL = 2

export const NOTIFY_TYPE_COLOR = {
    1: 'purple lighten-3',
    2: 'error',
    3: 'warning',
    4: 'error',
    11: 'yellow lighten-2',
    12: 'amber lighten-2',
    13: 'orange lighten-2',
    14: 'deep-orange lighten-2',
    15: 'brown lighten-2',
    16: 'blue-grey lighten-2',
    98: 'info',
    99: 'teal lighten-3',
}

export const GOODS_STATUS_OPTIONS = [
    {
        text: i18n.t('labels.continued'),
        value: 1
    },
    {
        text: i18n.t('labels.discontinued'),
        value: 100
    },
]

export const RECEIPT_DETAIL_STATUS_OPTIONS = [
    {
        text: i18n.t('labels.receipt_detail_status_0'),
        value: 'ny'
    },
    {
        text: i18n.t('labels.receipt_detail_status_1'),
        value: 'd'
    },
    {
        text: i18n.t('labels.receipt_detail_status_2'),
        value: 'y'
    },
]

export const RECEIPT_STATUS_OPTIONS = [
    {
        text: i18n.t('labels.requested'),
        value: 1
    },
    {
        text: i18n.t('labels.receipted'),
        value: 5
    },
]

export const PERIOD_OPTIONS = [
    {
        text: i18n.t("labels.last_number_days", { number: '01' }),
        value: 1,
    },
    {
        text: i18n.t("labels.last_number_days", { number: '07' }),
        value: 7,
    },
    {
        text: i18n.t("labels.last_number_days", { number: '10' }),
        value: 10,
    },
    {
        text: i18n.t("labels.last_number_days", { number: '30' }),
        value: 30,
    },
    {
        text: i18n.t("labels.last_number_days", { number: '90' }),
        value: 90,
    },
    {
        text: i18n.t("labels.last_number_days", { number: '180' }),
        value: 180,
    },
    {
        text: i18n.t("labels.last_number_days", { number: '360' }),
        value: 360,
    },
]

export const FORECAST_TYPE_OPTIONS = [
    {
        text: i18n.t("labels.forecast_type_1"),
        value: 1,
    },
    {
        text: i18n.t("labels.forecast_type_2"),
        value: 2,
    },
]

export const RECEIPT_VIEW_MODES = [
    {
        text: i18n.t('labels.summary'),
        value: 'view0'
    },
    {
        text: i18n.t('labels.by_session'),
        value: 'view1'
    },
]

export const CUSTOM_VIEW_WAREHOUSE_RECEIPT = 1
export const CUSTOM_VIEW_WAREHOUSE_RETURN = 2
export const CUSTOM_VIEW_WAREHOUSE_GOODS = 3
export const CUSTOM_VIEW_WAREHOUSE_ORDER_ONLINE = 4

export const CUSTOM_VIEW_POS_RECEIPT = 11
export const CUSTOM_VIEW_POS_RETURN = 12
export const CUSTOM_VIEW_POS_GOODS = 13
export const CUSTOM_VIEW_POS_ORDER_ONLINE = 14
export const CUSTOM_VIEW_POS_ORDER = 15

export const CUSTOM_VIEW_ADMIN_PAYABLE = 21

export const CUSTOM_VIEW_MATERIAL_WAREHOUSE_RECEIPT = 31
export const CUSTOM_VIEW_MATERIAL_WAREHOUSE_MATERIAL = 32

export const CUSTOM_VIEW_PRESENT_LIST = 41
export const CUSTOM_VIEW_PHYSICAL_COMBO_LIST = 42
export const CUSTOM_VIEW_PRIORITY_GOODS_QUANTITY = 43

export const CUSTOM_VIEW_BILL_HISTORY = 51
export const CUSTOM_VIEW_BILL_WAIT_PROCESS = 52
export const CUSTOM_VIEW_BILL_GOODS_NO_INVOICE = 53

export const CUSTOM_VIEW_CRM_LEAD_INVALID_PHONE = 61
export const CUSTOM_VIEW_CRM_LEAD_INCOMPLETE_DATA = 62
export const CUSTOM_VIEW_CRM_LEAD_TELESALE = 63
export const CUSTOM_VIEW_CRM_LEAD_COMPLETE_DATA = 64
export const CUSTOM_VIEW_CRM_LEAD_DOUBLE_CHECK = 65
export const CUSTOM_VIEW_CRM_LEAD_RESALE = 66

export const CUSTOM_VIEW_NORMAL = 999

export const ACCOUNT_TYPE_OPTIONS = [
    {
        text: i18n.t('labels.account_type_1'),
        value: 1,
    },
    {
        text: i18n.t('labels.account_type_2'),
        value: 2,
    },
]

export const POS_ORDER_TYPES = [
    {
        text: 'Bán hàng',
        value: 1
    },
    {
        text: 'Đổi trả',
        value: 2
    },
]

export const BUYER_HIDDEN_INFO_CODE = 'SSC-HIDDENINF-SSC'
export const REMOVE_ITEM_CODE = 'SSC-POSREMOVE-SSC'
export const GENDER_MALE_CODE = 'SSC-MALE-SSC'
export const GENDER_FEMALE_CODE = 'SSC-FEMALE-SSC'

export const POS_ORDER_STEP = {
    PHONE: 1,
    NAME: 2,
    GENDER: 3,
    BIRTH_DAY: 4,
    BIRTH_MONTH: 5,
    BIRTH_YEAR: 6,
    UID_PACKAGE: 7,
}

export const POS_ORDER_BUYER_STEP_KEYS = {
    1: 'phone',
    2: 'name',
    3: 'gender',
    4: 'birthday_day',
    5: 'birthday_month',
    6: 'birthday_year',
}

export const REQUEST_TYPE_OPTIONS = [
    {
        text: 'Cập nhật trạng thái đơn',
        value: 101,
        department: 1,
    },
    {
        text: 'Kiểm tra đóng gói',
        value: 201,
        department: 2,
    },
    {
        text: 'Kiểm tra nhập hoàn',
        value: 202,
        department: 3,
    },
    {
        text: 'Giục giao hàng',
        value: 301,
        department: 3,
    },
    {
        text: 'Sửa tiền thu hộ',
        value: 302,
        department: 3,
    },
    {
        text: 'Huỷ giao hàng',
        value: 303,
        department: 3,
    },
    {
        text: 'Hẹn giao hàng',
        value: 304,
        department: 3,
    },
    {
        text: 'Sửa thông tin liên hệ',
        value: 305,
        department: 3,
    },
    {
        text: 'Kiểm tra đối soát',
        value: 401,
        department: 4,
    },
    {
        text: 'Khác',
        value: 0,
        department: null,
    },
]

export const REQUEST_DEPARTMENT_OTPIONS = [
    {
        text: 'Hệ thống',
        value: 1,
    },
    {
        text: 'Kho',
        value: 2,
    },
    {
        text: 'Vận chuyển',
        value: 3,
    },
    {
        text: 'Kế toán',
        value: 4,
    },
]

export const ORDER_REQUEST_DEFAULT = {
    tracking_id: null,
    type: null,
    incharge_department: null,
    content: null,
    cash_on_delivery: '',
    request_new_cod: '',
}


export const POS_PROMOTION_TYPES = [
    {
        text: 'Giá trị hóa đơn',
        color: 'pl-2 text-left',
        value: 1,
    },
    {
        text: 'Mã SP',
        color: 'pl-2 text-left',
        value: 2,
    },
    {
        text: 'Combo nhiều mã SP',
        color: 'pl-2 text-left',
        value: 3,
    },
    {
        text: 'UID',
        color: 'font-weight-medium',
        value: 4,
    }
]


export const WARNING_TYPES = [
    {
        text: 'Sản phẩm',
        value: 1,
    },
    {
        text: 'Đơn hàng',
        value: 2,
    }
]

export const ITEM_TYPES = [
    {
        text: 'Sản phẩm',
        value: 1,
    },
]

export const WARNING_WARNING_TYPES = [
    {
        text: 'Làm muộn',
        value: 1,
    },
    {
        text: 'Nghỉ sớm',
        value: 2,
    },
    {
        text: 'Nghỉ trong ca',
        value: 3,
    },
]

export const WARNING_WORK_TYPES = [
    {
        text: 'Nhập kho',
        value: 1,
    },
    {
        text: 'Lên kệ',
        value: 2,
    },
    {
        text: 'Pickup',
        value: 3,
    },
    {
        text: 'Đóng gói',
        value: 4,
    },
    {
        text: 'BGVC',
        value: 5,
    },
    {
        text: 'Nhận hoàn',
        value: 6,
    },
    {
        text: 'Nhập hoàn',
        value: 7,
    },
    {
        text: 'LK. Hàng hoàn',
        value: 8,
    },
]

export const WARNING_CONFIG_DEFAULT = [
    {
        work_type: 1,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 2,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 3,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 4,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 5,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 2,
    },
    {
        work_type: 6,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 7,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 8,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
]

export const PACKAGING_TYPES = [
    'carton', 'pe', 'kraft_bubble', 'no_pack', 'bubble', 'non_woven_fabric', 'labels', 'ink', 'tape', 'paper', 'ribbon'
]

export const PACKAGING_UNITS = [
    {
        value: 'cuộn',
        text: i18n.t('labels.roll'),
    },
    {
        value: 'bó',
        text: i18n.t('labels.bundle'),
    },
    {
        value: 'hộp',
        text: i18n.t('labels.box'),
    },
    {
        value: 'tập',
        text: i18n.t('labels.pile'),
    },
    {
        value: 'cây',
        text: i18n.t('labels.shrink'),
    },
]
export const SOURCE_TYPES = [1, 2, 3, 0]

export const FIND_GOODS_CUSTOMER_STATUS = [0, 1, 2, 3]
export const FIND_GOODS_STATUS = [1, 2, 3, 4, 5]

export const ZALO_ZNS_TYPES = [1, 2, 3, 4, 5, 6, 7]

export const ZALO_SENT_STATUS_OPTIONS = [
    { text: 'Gửi thành công', value: 0 },
    { text: 'Xảy ra lỗi không xác định, vui lòng thử lại sau', value: -100 },
    { text: 'Ứng dụng gửi ZNS', value: -101 },
    { text: 'Ứng dụng gửi ZNS', value: -102 },
    { text: 'Ứng dụng chưa được kích hoạt', value: -103 },
    { text: 'Secret key của ứng dụng không hợp lệ', value: -104 },
    { text: 'Ứng dụng gửi ZNS chưa đươc liên kết với OA nào', value: -105 },
    { text: 'Phương thức không được hỗ trợ', value: -106 },
    { text: 'ID thông báo không hợp lệ', value: -107 },
    { text: 'Số điện thoại không hợp lệ', value: -108 },
    { text: 'ID mẫu ZNS không hợp lệ', value: -109 },
    { text: 'Phiên bản Zalo app không được hỗ trợ. Người dùng cần cập nhật phiên bản mới nhất', value: -110 },
    { text: 'Mẫu ZNS không có dữ liệu', value: -111 },
    { text: 'Nội dung mẫu ZNS không hợp lệ', value: -112 },
    { text: 'Không thể tạo QR code, vui lòng kiểm tra lại', value: -1123 },
    { text: 'Button không hợp lệ', value: -113 },
    { text: 'Người dùng không nhận được ZNS vì các lý do: Trạng thái tài khoản, Tùy chọn nhận ZNS, Sử dụng Zalo phiên bản cũ, hoặc các lỗi nội bộ khác', value: -114 },
    { text: 'Tài khoản ZNS không đủ số dư', value: -115 },
    { text: 'Nội dung không hợp lệ', value: -116 },
    { text: 'OA hoặc ứng dụng gửi ZNS chưa được cấp quyền sử dụng mẫu ZNS này', value: -117 },
    { text: 'Tài khoản Zalo không tồn tại hoặc đã bị vô hiệu hoá', value: -118 },
    { text: 'Tài khoản không thể nhận ZNS', value: -119 },
    { text: 'OA chưa được cấp quyền sử dụng tính năng này', value: -120 },
    { text: 'Mẫu ZNS không có nội dung', value: -121 },
    { text: 'Body request không đúng định dạng JSON', value: -122 },
    { text: 'Giải mã nội dung thông báo RSA thất bại', value: -123 },
    { text: 'Mã truy cập không hợp lệ', value: -124 },
    { text: 'ID Official Account không hợp lệ', value: -125 },
    { text: 'Ví (development mode) không đủ số dư', value: -126 },
    { text: 'Template test chỉ có thể được gửi cho quản trị viên', value: -127 },
    { text: 'Mã encoding key không tồn tại', value: -128 },
    { text: 'Không thể tạo RSA key, vui lòng thử lại sau', value: -129 },
    { text: 'N ội dung mẫu ZNS vượt quá giới hạn kí tự', value: -130 },
    { text: 'Mẫu ZNS chưa được phê duyệt', value: -131 },
    { text: 'Tham số không hợp lệ', value: -132 },
    { text: 'Mẫu ZNS này không được phép gửi vào ban đêm (từ 22h-6h)', value: -133 },
    { text: 'Người dùng chưa phản hồi gợi ý nhận ZNS từ OA', value: -134 },
    { text: 'OA chưa có quyền gửi ZNS (chưa được xác thực, đang sử dụng gói miễn phí)', value: -135 },
    { text: 'Cần kết nối với ZCA để sử dụng tính năng này', value: -136 },
    { text: 'Thanh toán ZCA thất bại (ví không đủ số dư,…)', value: -137 },
    { text: 'Ứng dụng gửi ZNS chưa có quyền sử dụng tính năng này', value: -138 },
    { text: 'Người dùng từ chối nhận loại ZNS này', value: -139 },
    { text: 'OA chưa được cấp quyền gửi ZNS hậu mãi cho người dùng này', value: -140 },
    { text: 'Người dùng từ chối nhận ZNS từ Official Account', value: -141 },
    { text: 'RSA key không tồn tại, vui lòng gọi API tạo RSA key', value: -142 },
    { text: 'RSA key đã tồn tại, vui lòng gọi API lấy RSA key', value: -143 },
    { text: 'OA đã vượt giới hạn gửi ZNS trong ngày', value: -144 },
    { text: 'OA không được phép gửi loại nội dung ZNS này', value: -145 },
    { text: 'Mẫu ZNS này đã bị vô hiệu hóa do chất lượng gửi thấp', value: -146 },
    { text: 'Mẫu ZNS đã vượt giới hạn gửi trong ngày', value: -147 },
    { text: 'Không tìm thấy ZNS journey token', value: -148 },
    { text: 'ZNS journey token không hợp lệ', value: -149 },
    { text: 'ZNS journey token đã hết hạn', value: -150 },
    { text: 'Không phải mẫu ZNS E2EE', value: -151 },
    { text: 'Lấy E2EE key thất bại', value: -152 },
]
export const ZALO_DELIVERY_STATUS_OPTIONS = [
    {
        text: 'Đã nhận',
        value: 1
    },
    {
        text: 'Chưa nhận',
        value: 0
    },
]

export const INDUSTRY_MATERIAL_ID = 25
export const INDUSTRY_ACCESSORY_ID = 26

export const MATERIAL_CLASSIFY_MATERIAL = 1
export const MATERIAL_CLASSIFY_ACCESSORY = 2

export const GOODS_PRODUCTION_REQUIREMENT_STATUS = [
    {
        text: 'Chờ duyệt',
        value: 1
    },
    {
        text: 'Đang duyệt',
        value: 2
    },
    {
        text: 'Đã duyệt',
        value: 3
    },
    {
        text: 'Từ chối',
        value: 4
    },
]

export const TRANSFER_BREAK_TYPES = [
    {
        text: i18n.t('labels.transfer_type_1'),
        value: 1
    },
    {
        text: i18n.t('labels.transfer_type_2'),
        value: 2
    },
    {
        text: i18n.t('labels.transfer_type_3'),
        value: 3
    },
    {
        text: i18n.t('labels.transfer_type_4'),
        value: 4
    },
]

export const WORKING_TIME_SOURCES = {
    OFFICE: 1,
    WAREHOUSE: 2,
    POS: 3,
    MATERIAL: 4
}

export const PUBLISH_INVOICE_OPTIONS = [
    {
        text: i18n.t('labels.publish_invoice_0'),
        value: 0
    },
    {
        text: i18n.t('labels.publish_invoice_1'),
        value: 1
    },
    /* {
        text: i18n.t('labels.publish_invoice_2'),
        value: 2
    }, */
]

export const ORDER_PUBLISH_INVOICE_TYPES = [
    {
        text: i18n.t('labels.by_goods_price'),
        value: 0
    },
    {
        text: i18n.t('labels.by_invoice_price'),
        value: 1
    },
]

export const INVOICE_TYPE_PRODUCT_ORDER = [
    {
        text: 'Gộp',
        value: 1
    },
    {
        text: 'Từng lần',
        value: 2
    },
]

export const INVOICE_TYPE_CREATE_EXPORT = [
    {
        text: 'Thực xuất',
        value: 1
    },
    /* {
        text: 'Xuất theo tạo đơn',
        value: 2
    }, */
]

export const BILL_TYPE_COMPANY = [
    {
        text: 'Misa',
        value: 1
    },
    {
        text: 'Fast Invoice',
        value: 2
    },
    /* {
        text: 'Thái Sơn',
        value: 3
    }, */
]

export const INVOICE_TYPE = [
    /* {
        text: 'Hóa đơn GTGT',
        value: 1
    }, */
    {
        text: 'Hóa đơn thường',
        value: 2
    },
    {
        text: 'Hóa đơn từ MTT',
        value: 1002
    },
]

export const INVOICE_STATUS = [
    {
        text: 'Chờ cấp mã',
        value: 0
    },
    {
        text: 'Có hiệu lực',
        value: 2
    },
    {
        text: 'Đã bị điều chỉnh',
        value: 5
    },
]

export const INVOICE_TAX_STATUS = [
    {
        text: 'Đang xử lý',
        value: 0
    },
    {
        text: 'Có hiệu lực',
        value: 3
    },
    {
        text: 'Không hợp lệ',
        value: 5
    },
]

export const INVOICE_PORTAL_TAX_STATUS = [
    {
        text: 'Đang xử lý',
        value: 0
    },
    {
        text: 'Xác thực',
        value: 1002
    },
    {
        text: 'Đã bị điều chỉnh',
        value: 1003
    },
    {
        text: 'Đã bị thay thế',
        value: 1004
    },
    {
        text: 'Hủy',
        value: 1009
    },
]

export const INVOICE_WAIT_STATUS = [
    {
        text: 'Không cần xử lý',
        value: 0
    },
    {
        text: 'Chưa xử lý',
        value: 1
    },
    {
        text: 'Đã xuất hóa đơn',
        value: 2
    },
    {
        text: 'Không xuất hóa đơn',
        value: 3
    },
]

export const INVOICE_CLASSIFY = [
    {
        text: 'Tự động',
        value: 1
    },
    {
        text: 'Cần xử lý',
        value: 2
    },
]

export const ORDER_SOURCES = [
    {
        text: '# Sàn',
        value: 0
    },
    {
        text: 'Shopee',
        value: 1
    },
    {
        text: 'Lazada',
        value: 2
    },
    {
        text: 'Tiktok Shop',
        value: 5
    },
]

export const E_MARKETS = [
    {
        text: 'Shopee',
        value: 1
    },
    {
        text: 'Lazada',
        value: 2
    },
    {
        text: 'Tiktok Shop',
        value: 5
    },
]

export const TRANSFER_TYPES = [
    {
        text: i18n.t("labels.location"),
        value: 1,
        disabled: false,
    },
    {
        text: i18n.t("labels.proportion"),
        value: 2,
        disabled: true,
    },
    {
        text: i18n.t("labels.sales_speed"),
        value: 3,
        disabled: true,
    },
    {
        text: i18n.t("labels.automatically_periodically"),
        value: 4,
        disabled: true,
    },
]

export const VN_VAT_OPTIONS = [
    {
        text: '0%',
        value: 0,
    },
    {
        text: '5%',
        value: 5,
    },
    {
        text: '8%',
        value: 8,
    },
    {
        text: '10%',
        value: 10,
    },
    {
        text: 'Không chịu thuê',
        value: -1,
    },
    /* {
        text: 'Bỏ trống thuế suất',
        value: -9,
    }, */
]

export const ORDER_INVOICE_DEFAULT_DATA = {
    is_publish: null,
    buyer_name: null,
    tax_code: null,
    address: null,
    phone: null,
    email: null,
    identity_number: null
}

export const ORDER_RECEIVER_DEFAULT_DATA = {
    receiver_name: null,
    receiver_phone_number: null,
    id_city: null,
    id_county: null,
    id_ward: null,
    address: null,
    id_source: null,

    publish_invoice: null,
    publish_invoice_type: 0,
    invoice_name: null,
    invoice_tax_code: null,
    invoice_address: null,
    invoice_phone: null,
    invoice_email: null,
    invoice_identity: null,
}

export const ZNS_ACTION_TYPES = [
    {
        text: i18n.t('labels.zalo_template_action_type_1'),
        value: 1
    },
    {
        text: i18n.t('labels.zalo_template_action_type_2'),
        value: 2
    },
    {
        text: i18n.t('labels.zalo_template_action_type_3'),
        value: 3
    },
]

export const PRESENT_STATUS = [
    {
        text: i18n.t('labels.wait_confirm'),
        value: 0
    },
    {
        text: i18n.t('labels.start_pending'),
        value: 1
    },
    {
        text: i18n.t('labels.on_going'),
        value: 2
    },
    {
        text: i18n.t('labels.ended'),
        value: 3
    },
    {
        text: i18n.t('labels.ended_early'),
        value: 4
    },
    {
        text: i18n.t('labels.cancelled'),
        value: 5
    },
]

export const PHYSICAL_COMBO_STATUS = [
    {
        text: i18n.t('labels.start_pending'),
        value: 1
    },
    {
        text: i18n.t('labels.on_going'),
        value: 2
    },
    {
        text: i18n.t('labels.ended'),
        value: 3
    },
    {
        text: i18n.t('labels.ended_early'),
        value: 4
    },
    {
        text: i18n.t('labels.cancelled'),
        value: 5
    },
]

export const PRIORITY_GOODS_QUANTITY_STATUS = [
    {
        text: i18n.t('labels.start_pending'),
        value: 1
    },
    {
        text: i18n.t('labels.on_going'),
        value: 2
    },
    {
        text: i18n.t('labels.ended'),
        value: 3
    },
    {
        text: i18n.t('labels.ended_early'),
        value: 4
    },
    {
        text: i18n.t('labels.cancelled'),
        value: 5
    },
]

export const PRESENT_TYPES = [
    {
        text: i18n.t('labels.by_product'),
        value: 1
    },
    {
        text: i18n.t('labels.by_value'),
        value: 2
    },
]

export const PREVENT_PICKUP_TIMES = [
    {
        text: i18n.t('labels.number_hours', { number: 1 }),
        value: 60
    },
    {
        text: i18n.t('labels.number_hours', { number: 2 }),
        value: 120
    },
    {
        text: i18n.t('labels.number_hours', { number: 3 }),
        value: 180
    },
    {
        text: i18n.t('labels.number_hours', { number: 4 }),
        value: 240
    },
    {
        text: i18n.t('labels.number_hours', { number: 5 }),
        value: 300
    },
]

export const MAX_SELECT_RANGE = 31

export const CREATE_PHONE_LOGICS = [
    {
        text: i18n.t('labels.create_logic_phone_1'),
        value: 1
    },
    {
        text: i18n.t('labels.create_logic_phone_2'),
        value: 2
    },
]

export const CREATE_LEAD_LOGICS = [
    {
        text: i18n.t('labels.create_logic_lead_1'),
        value: 1
    },
    {
        text: i18n.t('labels.create_logic_lead_2'),
        value: 2
    },
    {
        text: i18n.t('labels.create_logic_lead_3'),
        value: 3
    },
    {
        text: i18n.t('labels.create_logic_lead_4'),
        value: 4
    },
]

export const LEAD_MODELS = [
    {
        text: i18n.t('labels.lead_model_1'),
        value: 1
    },
    {
        text: i18n.t('labels.lead_model_2'),
        value: 2
    },
]

export const LEAD_PROCESS_COUNT = [
    {
        text: i18n.t('labels.lead_process_count_0'),
        value: 0
    },
    {
        text: i18n.t('labels.lead_process_count_number', { number: 1 }),
        value: 1
    },
    {
        text: i18n.t('labels.lead_process_count_number', { number: 2 }),
        value: 2
    },
    {
        text: i18n.t('labels.lead_process_count_number', { number: 3 }),
        value: 3
    },
    {
        text: i18n.t('labels.lead_process_count_number', { number: 4 }),
        value: 4
    },
    {
        text: i18n.t('labels.lead_process_count_number', { number: 5 }),
        value: 5
    },
]

export const LEAD_RETRY_TIME = [
    {
        text: i18n.t("labels.after_number_minutes", { number: "05" }),
        value: 5,
      },
      {
        text: i18n.t("labels.after_number_minutes", { number: "15" }),
        value: 15,
      },
      {
        text: i18n.t("labels.after_number_minutes", { number: "30" }),
        value: 30,
      },
      {
        text: i18n.t("labels.after_number_hours", { number: "01" }),
        value: 60,
      },
      {
        text: i18n.t("labels.after_number_hours", { number: "02" }),
        value: 120,
      },
      {
        text: i18n.t("labels.after_number_hours", { number: "04" }),
        value: 240,
      },
      {
        text: i18n.t("labels.after_number_hours", { number: "06" }),
        value: 360,
      },
      {
        text: i18n.t("labels.after_number_hours", { number: "08" }),
        value: 480,
      },
      {
        text: i18n.t("labels.after_number_hours", { number: "10" }),
        value: 600,
      },
      {
        text: i18n.t("labels.after_number_hours", { number: "12" }),
        value: 720,
      },
      {
        text: i18n.t("labels.after_number_hours", { number: "24" }),
        value: 1440,
      },
]

export const REGEX_PHONE_BY_COUNTRY = {
    1: [
        /^\+840/g,
        /^840/g,
        /^\+84/g,
        /^84/g,
    ],
    2: [
        /^\+630/g,
        /^630/g,
        /^\+63/g,
        /^63/g,
    ]
}

export const REGEX_PHONE_TO_NUMBER_BY_COUNTRY = {
    1: '0',
    2: '0',
}